import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import HeroSection from "../components/ContactPage/HeroSection"
import FormSection from "../components/ContactPage/FormSection"

const ContactPage = () => {
  return (
    <Layout hideFooter>
      <Seo title="Contact" />
      <HeroSection />
      <FormSection />
    </Layout>
  )
}

export default ContactPage
