import React from "react"

const FormSection = () => {
  return (
    <section>
      <div className="container mx-auto max-w-6xl px-6 py-28 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="space-y-10">
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            Say hello!
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Careers</p>
                <p>jobs@forist.com</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-400">Business inquiries</p>
                <p>contact@forist.com</p>
              </div>
            </div>

            <div className="text-sm text-gray-400 space-y-6">
              <div className="space-y-1">
                <p>Strada Investitiei 29</p>
                <p>1150 Bucharest</p>
                <p>Romania</p>
              </div>
              <div className="space-y-1">
                <p>Phone</p>
                <p>+(222) 123 4352</p>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <ul className="flex space-x-6">
              <li>Instagram</li>
              <li>LinkedIn</li>
              <li>Twitter</li>
            </ul>
            <p className="text-sm text-gray-400 space-y-6">
              &copy; 2021 Forist. All rights reserved.
            </p>
          </div>
        </div>

        <div>
          <div className="flex flex-col space-y-5">
            <input
              type="text"
              placeholder="Name"
              className="border-gray-200 placeholder-gray-400 placeholder-opacity-80 focus:ring-blue-200 focus:border-blue-200 transition duration-200"
            />
            <input
              type="email"
              placeholder="Email"
              className="border-gray-200 placeholder-gray-400 placeholder-opacity-80 focus:ring-blue-200 focus:border-blue-200 transition duration-200"
            />
            <input
              type="text"
              placeholder="Subject"
              className="border-gray-200 placeholder-gray-400 placeholder-opacity-80 focus:ring-blue-200 focus:border-blue-200 transition duration-200"
            />
            <textarea
              placeholder="Message"
              rows="4"
              className="border-gray-200 placeholder-gray-400 placeholder-opacity-80 focus:ring-blue-200 focus:border-blue-200 transition duration-200"
            />
          </div>
          <button className="mt-8 py-4 px-6 bg-blue-50">Send Message</button>
        </div>
      </div>
    </section>
  )
}

export default FormSection
