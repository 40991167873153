import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeroSection = () => (
  <>
    <section className="min-h-[60vh] flex items-center">
      <div className="container mx-auto max-w-6xl px-6 py-10">
        <h1 className="font-medium text-5xl leading-normal tracking-tighter">
          We'd love to work together
          <br />
          Don't hesitate, drop us a line.
        </h1>
        <p className="mt-5 leading-loose">
          Let's talk about your awesome project idea, we'll give you our
          expertise
          <br />
          and pricing and in the end we'll create an amazing product.
        </p>
      </div>
    </section>
    <section>
      <StaticImage
        src="../../assets/images/contact-hero.jpg"
        placeholder="blurred"
        layout="fullWidth"
        alt="contact hero image"
      />
    </section>
  </>
)

export default HeroSection
